<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-card
      no-body
      class="mb-2"
    >
      <div class="m-2">
        <b-row>
          <b-col>
            <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="IMPORT WEB :"
              label-for="input-lg"
            >
              <b-input-group>
                <b-form-input
                  id="input-lg"
                  v-model="inputUrl"
                  placeholder="URL IMPORT"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="getData"
                  >
                    <span class="mr-25 align-middle">{{ $t("GET_FROM_URL") }}</span>
                    <feather-icon
                      icon="GlobeIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="IMPORT JSON :"
              label-for="input-lg"
            >
              <b-form-file
                ref="uploadFileJson"
                class="mb-2"
                @change="updateFiles"
              />
            </b-form-group>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              @click="clearData"
            >
              <feather-icon
                icon="RefreshIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Reset') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col>

              <label>{{ $t('Data') }} {{ queriedItems }} {{ $t('List') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector v-select-custom"
                @input="pageSize"
              />
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">
                <!-- <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')+'...'"
                /> -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="d-flex"
                  @click="selectAllRows"
                >
                  <feather-icon
                    icon="ListIcon"
                  />
                  <span class="mr-25 align-middle">{{ $t("SELECT_ALL") }}</span>

                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="d-flex ml-2"
                  @click="clearSelected"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                  <span class="mr-25 align-middle">{{ $t("CLEAR_SELECTED") }}</span>

                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.modal-prevent-closing
                  variant="primary"
                  class="d-flex ml-2"
                >
                  <feather-icon
                    icon="PlusIcon"
                  />
                  <span class="mr-25 align-middle">{{ $t("IMPORT_DATA") }}</span>

                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="selectableTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          :select-mode="selectMode"
          :fields="tableColumns"
          :per-page="perPage"
          :current-page="currentPage"
          primary-key="pbrand_id"
          show-empty
          :empty-text="$t('No matching records found')"
          selectable
          @row-selected="onRowSelected"
          style="height: 60vh;"

        >
          <!-- Example scoped slot for select state illustrative purposes -->
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <!-- Column: Images -->
          <template #cell(pbrand_image)="data">
            <b-row>
              <b-img
                :src="'https://gooapp.quinlcdn.com/'+data.item.pbrand_image"
                fluid
                thumbnail
                width="100px"
                :alt="'Product_'+data.item.pbrand_id"
              />
            </b-row>
          </template>
          <!-- Column: Name -->
          <template #cell(productslang_name)="data">
            <b-row>
              {{ data.item.productslang_name }}
            </b-row>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span>
            </b-col>
          </b-row>
        </div>
        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="queriedItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="nextPage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-card>
    </b-overlay>
    <!-- modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      :title="$t('IMPORT_DATA')"
      :ok-title="$t('IMPORT')"
      cancel-variant="outline-secondary"
      @ok="importData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BPagination,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BFormFile,
  BButton,
  BTable,
  BOverlay,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import { useToast } from 'vue-toastification/composition'
import perPageOptions from '@/perPageOptions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/productBrand'
import axios from 'axios'

const https = require('https')

const STORE_MODULE_NAME = 'productBrand'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormFile,
    BButton,
    // Sidebar,
    BTable,
    BOverlay,
    BModal,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      avatarText,
      inputUrl: 'https://api.gooapp.me/jsonfiles/brand_150_th.json',
      respData: [],
      perPageOptions,
      show: false,
      queriedItems: 0,
      currentPage: 1,
      perPage: localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 50,
      totalRows: 1,
      selectMode: 'multi',
      selected: [],
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'selected', label: this.$t('SELECTED'), sortable: false },
        { key: 'pbrand_image', label: this.$t('IMAGE'), sortable: false },
        { key: 'pbrand_id', label: this.$t('ID'), sortable: false },
        { key: 'pbrandlang_name', label: this.$t('NAME'), sortable: false },
        // { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) { store.registerModule(STORE_MODULE_NAME, storeModule) }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) { store.unregisterModule(STORE_MODULE_NAME) }
  },
  methods: {
    importData() {
      if (this.selected.length > 0) {
        const obj = {
          dataSelected: this.selected,
        }
        this.show = true
        store
          .dispatch(`${STORE_MODULE_NAME}/import`, obj)
          .then(() => {
            this.show = false

            this.showToast(
              'warning',
              'top-right',
              `${this.$t('IMPORT_DATA')}!`,
              'PlusIcon',
              this.$t('IMPORT_DATA'),
            )
          }).catch(error => {
            this.show = false

            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
          })
      } else {
        this.showToast(
          'danger',
          'top-right',
          `${this.$t('NOT_SELECT_DATA')}!`,
          'XIcon',
          this.$t('NOT_SELECT_DATA'),
        )
      }
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    onRowSelected(items) {
      this.selected = items
    },
    clearData() {
      this.inputUrl = ''
      this.currentPage = 1
      this.respData = []
      this.selected = []
      this.queriedItems = 0
      this.$refs.uploadFileJson.reset()
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
    },
    nextPage(page) {
      this.currentPage = page
    },
    getData() {
      const instance = axios.create({
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      })
      axios
        .get(`${this.inputUrl.trim()}`, { httpsAgent: instance })
        .then(response => {
          
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateFiles(input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          // const result = JSON.parse(e.target.result)
          this.respData = JSON.parse(e.target.result)
          this.queriedItems = this.respData.length
        }
        reader.readAsText(input.target.files[0])
      }
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
